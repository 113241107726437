import React from 'react'
import SEO from "../../../../components/atoms/seo"

import Layout from '../../../../components/organisms/layout'
import Heading from '../../../../components/molecules/heading'
import Questionnaire from '../../../../components/organisms/questionnaire'
import KeyFeatures from '../../../../components/molecules/key-features'

import steps from "../../../../data/pages/questionnaire/product/2024/tcw46/steps.json"
import verifyInformation from "../../../../data/pages/questionnaire/product/2024/tcw46/verify.json"
import content from "../../../../data/pages/questionnaire/product/2024/tcw46/content.json"

const QuestionnairePage = () => (
  <Layout showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title={content.heading.title}
        subTitle=''
        weight='light'
        color='dark'
    />
    <Questionnaire 
      steps={steps}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      // formType="wall-bed"
    />
    <KeyFeatures
      features={[
        {
            title: 'We market',
            image: 'icon-2.png',
            imageAlt: ''
        },
        {
            title: 'We sell',
            image: 'icon-3.png',
            imageAlt: ''
        },
        {
          title: 'We build',
          image: 'icon-1.png',
          imageAlt: ''
      }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
